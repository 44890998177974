import React from 'react';
import { cva } from 'class-variance-authority';

const inputVariants = cva(
  'block w-full px-3 py-2 border rounded-md text-sm focus:outline-none focus:ring focus:ring-opacity-50',
  {
    variants: {
      variant: {
        primary: 'border-gray-300 focus:ring-blue-500',
        error: 'border-red-500 focus:ring-red-500',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

export const Input = React.forwardRef(
  ({ className, variant, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={`${inputVariants({ variant })} ${className}`}
        {...props}
      />
    );
  }
);

Input.displayName = 'Input';
