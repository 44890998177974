'use client';

import { Card } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import {
  ChevronRight,
  MapPin,
  Building2,
  History,
  Users,
  Share2,
  MessageSquare,
  Bookmark,
  ExternalLink,
} from 'lucide-react';
import JobSidebar from '../../components/JobSidebar';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SnapshotInfo from '../../components/snapshotInfo';
import formatDateTime from '../../components/formateDateTime';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function DetailsCake() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const HOST = window.location.host;
  const [jobData, setJobData] = useState(null);
  useEffect(() => {
    fetch(`${API_BASE_URL}/lunar-lake/${uuid}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const RESULT = data.data.result;
        setJobData(RESULT);
      })
      .catch((error) => {
        console.error('Error fetching job data:', error);
        navigate('/404');
      });
  }, [uuid, navigate]);
  if (!jobData) return <p>Loading...</p>;

  return (
    <div className="container mx-auto px-4 py-6">
      <SnapshotInfo
        timeCreated={formatDateTime(jobData.time_created)}
        timeUpdated={formatDateTime(jobData.time_updated)}
        originalUrl={jobData.original_url}
        quickUrl={`${HOST}/snapshot/${jobData.source}/${jobData.uuid}`}
        uuid={jobData.uuid}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* 主要內容區域 */}
        <div className="md:col-span-2">
          <h1 className="text-2xl font-bold mb-4">{jobData.title}</h1>
          <div className="mb-4">
            <nav className="flex items-center space-x-2 text-sm text-muted-foreground">
              {/* 第一部分 */}
              <span>{jobData.classification[0]}</span>
              <ChevronRight className="w-4 h-4 text-gray-500" />
              {/* 第二部分 */}
              <span className="font-semibold text-primary">
                {jobData.classification[1]}
              </span>
            </nav>
          </div>
          <div className="flex items-center gap-2 text-muted-foreground mb-4">
            <span className="flex items-center gap-1">
              <Building2 className="w-4 h-4" />
              {jobData.id_company.name}
            </span>
            <span className="flex items-center gap-1">
              <History className="w-4 h-4" /> 職缺於{' '}
              {jobData.time_updated_original
                ? formatDateTime(jobData.time_updated_original)
                : '未知時間'}{' '}
              更新
            </span>
          </div>

          {/* 工作內容區域 */}
          <Card className="p-6 my-4">
            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold mb-4">職缺描述</h2>
              <JobDescription rawHtml={jobData.description} />
            </div>
          </Card>

          <Card className="p-6 my-4">
            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold mb-4">職務需求</h2>
              <JobDescription rawHtml={jobData.required_skill} />
            </div>
          </Card>

          <Card className="p-6 my-4">
            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold mb-4">面試流程</h2>
              <JobDescription rawHtml={jobData.apply_way} />
            </div>
          </Card>

          <Card className="p-6 my-4">
            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold mb-4">應徵問題</h2>
              <ul className="list-disc pl-5">
                {jobData.required_other.questions?.map((question, index) => (
                  <li key={index} className="mb-2">
                    {question}
                  </li>
                ))}
              </ul>
            </div>
          </Card>
        </div>

        {/* 右側資訊欄 */}
        <JobSidebar data={jobData} />
      </div>
    </div>
  );
}

export function JobDescription({ rawHtml }) {
  return (
    <div style={{ paddingLeft: '20px' }}>
      <div dangerouslySetInnerHTML={{ __html: rawHtml }} />
    </div>
  );
}
