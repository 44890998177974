import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Star, Eye } from 'lucide-react';
import Cookies from 'universal-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const FavoriteJobCard = ({ favorite, setFavoriteData }) => {
  const [comment, setComment] = useState(favorite.comments || '');
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const getCsrfToken = () => {
    const cookies = new Cookies();
    return cookies.get('csrftoken');
  };

  const handleUpdateComment = async () => {
    setIsUpdating(true);
    setUpdateError(null);
    setSuccessMessage(null);
    try {
      const csrfToken = getCsrfToken();
      const response = await fetch(
        `${API_BASE_URL}/api/favorites/${favorite.id}/`,
        {
          method: 'PATCH', // 或 'PUT'，取決於你的 API 設計
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken, // 如果需要 CSRF Token
            // 'Authorization': `Bearer ${token}`, // 如果使用 Token-based auth，取消註釋並設置 token
          },
          credentials: 'include', // 如果使用 session-based auth
          body: JSON.stringify({ comments: comment }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || '更新備註失敗');
      }

      const updatedFavorite = await response.json();

      // 更新父組件的狀態
      setFavoriteData((prevData) =>
        prevData.map((fav) =>
          fav.id === updatedFavorite.id ? updatedFavorite : fav
        )
      );

      setSuccessMessage('備註已更新');
    } catch (err) {
      console.error('更新備註失敗:', err);
      setUpdateError(err.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card className="flex flex-col md:flex-row p-4 shadow-md rounded-lg hover:shadow-lg transition-shadow duration-200">
      {/* 左側區域：職缺詳細資訊 */}
      <div className="flex flex-col justify-between w-full">
        <CardHeader className="p-0 mb-2">
          <CardTitle className="text-xl font-semibold">
            <a
              href={`/snapshot/${favorite.job.source}/${favorite.job.uuid}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:nounderline"
            >
              {favorite.job.title}
            </a>
          </CardTitle>
        </CardHeader>
        <CardContent className="p-0 space-y-1">
          <div className="text-gray-800 space-x-2">
            <span className="font-bold">公司名稱</span>
            <span>{favorite.job.id_company.name}</span>
          </div>
          <div className="text-gray-800 space-x-2">
            <span className="font-bold">求職地點</span>
            <span>{favorite.job.location}</span>
          </div>
          <div className="text-gray-800 space-x-2">
            <span className="font-bold">工作待遇</span>
            <span>{favorite.job.salary}</span>
          </div>
        </CardContent>
      </div>
      {/* 右側區域：收藏備註 */}
      <div className="flex-shrink-0 mr-0 md:mr-4 mb-4 md:mb-0">
        <div className="mt-4">
          <textarea
            id={`comment-${favorite.job.id}`}
            name={`comment-${favorite.job.id}`}
            rows="3"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            value={comment}
            onChange={handleCommentChange}
            maxLength="200"
          ></textarea>
          <div className="mt-2 flex justify-end items-center">
            {successMessage && (
              <span className="text-green-600 text-sm">{successMessage}</span>
            )}
            {updateError && (
              <span className="text-red-600 text-sm">錯誤: {updateError}</span>
            )}
            <Button
              variant="secondary"
              size="sm"
              onClick={handleUpdateComment}
              disabled={isUpdating}
              className="ml-2"
            >
              {isUpdating ? '更新中...' : '更新備註'}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};
const FavoriteJobs = () => {
  const [favoriteData, setFavoriteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // 定義異步函數來獲取數據
    const fetchFavoriteData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/favorites/`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${token}`, // 如果使用 Token-based auth
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json(); // 解析 JSON 數據
        setFavoriteData(data); // 更新狀態
      } catch (err) {
        console.error('Error fetching favorite data:', err);
        setError(err.message);
      } finally {
        setLoading(false); // 結束加載狀態
      }
    };

    // 調用異步函數
    fetchFavoriteData();
  }, []); // 空依賴數組，確保只在組件掛載時運行一次

  // 處理加載狀態
  if (loading) {
    return <div>Loading...</div>;
  }

  // 處理錯誤狀態
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">收藏工作</h2>
      {favoriteData.length === 0 ? (
        <p>您還沒有收藏任何職缺。</p>
      ) : (
        <div className="space-y-6">
          {favoriteData.map((favorite) => (
            <FavoriteJobCard
              key={favorite.job.id}
              favorite={favorite}
              setFavoriteData={setFavoriteData}
              API_BASE_URL={API_BASE_URL}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default FavoriteJobs;
