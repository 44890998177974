import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import Submit from './pages/Submit';
import Details104 from './pages/Details/details104';
import Details1111 from './pages/Details/details1111';
import DetailsCake from './pages/Details/detailsCake';
import NotFound from './pages/page404';
import Dashboard from './pages/Backend/Dashboard';
import LoginPage from './pages/login';
import UserProfile from './pages/Backend/UserProfile';

export default function AppRouter() {
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/snapshot/104/:uuid" element={<Details104 />} />
          <Route path="/snapshot/1111/:uuid" element={<Details1111 />} />
          <Route path="/snapshot/cake/:uuid" element={<DetailsCake />} />
          <Route path="/backend" element={<Dashboard />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/userprofile" element={<UserProfile />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}
