import React from 'react';

export const AvatarImage = ({ src, alt, size = 'w-12 h-12' }) => (
  <img src={src} alt={alt} className={`rounded-full object-cover ${size}`} />
);

export const AvatarFallback = ({
  fallbackText = 'User',
  size = 'w-12 h-12',
}) => (
  <div
    className={`flex items-center justify-center rounded-full bg-gray-200 ${size}`}
  >
    <span className="text-gray-600 text-sm font-medium">{fallbackText}</span>
  </div>
);

export const Avatar = ({
  src,
  alt = 'Avatar',
  fallbackText = 'User',
  size = 'w-12 h-12',
}) => {
  return src ? (
    <AvatarImage src={src} alt={alt} size={size} />
  ) : (
    <AvatarFallback fallbackText={fallbackText} size={size} />
  );
};
