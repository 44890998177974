'use client';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../components/ui/avatar';
import { Button } from '../../components/ui/button';
import { Camera, Briefcase, Search, Mail, Globe, LogOut } from 'lucide-react';
import { GiConsoleController } from 'react-icons/gi';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function UserProfile() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data from your backend
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/backend/user-profile/`, {
          credentials: 'include', // 確保傳遞 Cookies
        });
        if (response.ok) {
          const userData = await response.json();
          const result = userData.data.result;
          setUser(result);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }
  return (
    <div className="min-h-screen bg-[#F4A940]/10 flex items-center justify-center p-4">
      <Card className="w-full max-w-3xl shadow-lg">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div className="flex items-center space-x-2">
            <Camera className="h-6 w-6 text-[#F4A940]" />
            <Briefcase className="h-6 w-6 text-[#F4A940]" />
            <Search className="h-6 w-6 text-[#F4A940]" />
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
            <Avatar
              src={user.picture}
              alt={user.name}
              fallbackText={user.name.charAt(0)}
              size="h-24 w-24"
            />

            <div className="space-y-1 text-center md:text-left">
              <h2 className="text-2xl font-semibold">{user.name}</h2>
              <p className="text-muted-foreground">{user.email}</p>
            </div>
          </div>
          <div className="mt-6 grid gap-4 md:grid-cols-2">
            <Card>
              <CardContent className="flex items-center space-x-4 pt-6">
                <Mail className="h-10 w-10 text-[#F4A940]" />
                <div>
                  <p className="text-sm font-medium">Provider</p>
                  <p className="text-xl font-bold">{user.provider}</p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="flex items-center space-x-4 pt-6">
                <Globe className="h-10 w-10 text-[#F4A940]" />
                <div>
                  <p className="text-sm font-medium">Locale</p>
                  <p className="text-xl font-bold">{user.locale || 'N/A'}</p>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="mt-6 flex justify-end">
            {/* 要傳csrftoken所以先拿掉 */}
            {/* <Button 
              variant="outline" 
              className="bg-[#F4A940] text-white hover:bg-[#F4A940]/90"
              onClick={handleLogout}
            >
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </Button> */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
