import { FaInstagram, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <div className="flex items-center">
              <img
                src="/favicon.ico"
                alt="JobsCapsule Logo"
                className="h-10 w-10"
              />
              <span className="ml-3 text-xl font-bold text-gray-800">
                JobsCapsule
              </span>
            </div>
            <p className="text-gray-500 text-base">
              助你輕鬆掌握並優雅整理每一份職涯機遇。
            </p>
            <div className="flex space-x-6">
              <a href="#" className="text-gray-400 hover:text-[#F4A940]">
                <span className="sr-only">Facebook</span>
                <FaFacebook className="h-6 w-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-[#F4A940]">
                <span className="sr-only">Twitter</span>
                <FaTwitter className="h-6 w-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-[#F4A940]">
                <span className="sr-only">Instagram</span>
                <FaInstagram className="h-6 w-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-[#F4A940]">
                <span className="sr-only">Linkedin</span>
                <FaLinkedin className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; 2025 JobsCapsule, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
