'use client';

import { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu';
import { Menu, X, Search, Briefcase, Camera, User } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // 新增的狀態來儲存使用者資料

  useEffect(() => {
    // 檢查登入狀態
    fetch(`${API_BASE_URL}/backend/login-status/`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        const result = data.data.result;
        setIsAuthenticated(result.is_authenticated);
        if (result.is_authenticated) {
          fetchUserData(); // 如果已登入，呼叫 fetchUserData 取得使用者資料
        }
      })
      .catch((error) => {
        console.error('Error checking login status:', error);
      });
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/backend/user-profile/`, {
        credentials: 'include', // 確保傳遞 Cookies
      });
      if (response.ok) {
        const userData = await response.json();
        const result = userData.data.result;
        setUser(result);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <a href="/" className="flex-shrink-0">
              <div className="flex items-center">
                <Camera className="h-8 w-8 text-[#F4A940]" />
                <span className="ml-2 text-xl font-bold text-gray-800">
                  JobsCapsule
                </span>
              </div>
            </a>
            <div className="hidden md:block ml-10">
              <div className="flex items-baseline space-x-4">
                <a
                  href="/"
                  className="text-gray-600 hover:text-[#F4A940] px-3 py-2 rounded-md text-sm font-medium"
                >
                  Home
                </a>
                {/* <a href="/jobs" className="text-gray-600 hover:text-[#F4A940] px-3 py-2 rounded-md text-sm font-medium">Jobs</a>
                <a href="/saved" className="text-gray-600 hover:text-[#F4A940] px-3 py-2 rounded-md text-sm font-medium">Saved</a> */}
                <a
                  href="/about"
                  className="text-gray-600 hover:text-[#F4A940] px-3 py-2 rounded-md text-sm font-medium"
                >
                  About
                </a>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              {/* <div className="relative mr-2">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <Input
                  type="text"
                  placeholder="Search jobs..."
                  className="pl-10 pr-4 py-2 border rounded-md focus:ring-[#F4A940] focus:border-[#F4A940]"
                />
              </div> */}
              <DropdownMenu>
                <DropdownMenuTrigger aschild={true}>
                  <User className="h-5 w-5" />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {isAuthenticated ? (
                    <>
                      <DropdownMenuItem as="a" href="/userprofile">
                        Profile
                      </DropdownMenuItem>
                      <DropdownMenuItem as="a" href="/backend">
                        Settings
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        as="a"
                        href={`${API_BASE_URL}/accounts/logout/`}
                      >
                        Logout
                      </DropdownMenuItem>
                    </>
                  ) : (
                    <DropdownMenuItem as="a" href="/login">
                      Login
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <Button variant="ghost" onClick={() => setIsOpen(!isOpen)}>
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </Button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="/"
              className="text-gray-600 hover:text-[#F4A940] block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </a>
            {/* <a href="/jobs" className="text-gray-600 hover:text-[#F4A940] block px-3 py-2 rounded-md text-base font-medium">Jobs</a>
            <a href="/saved" className="text-gray-600 hover:text-[#F4A940] block px-3 py-2 rounded-md text-base font-medium">Saved</a> */}
            <a
              href="/about"
              className="text-gray-600 hover:text-[#F4A940] block px-3 py-2 rounded-md text-base font-medium"
            >
              About
            </a>
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            {isAuthenticated && user ? (
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <Avatar
                    src={user.picture}
                    alt={user.name}
                    fallbackText={user.name.charAt(0)}
                    size="h-10 w-10"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user.email}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <User className="h-10 w-10 rounded-full text-[#F4A940]" />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    訪客模式
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    請登入使用更多功能
                  </div>
                </div>
              </div>
            )}
            <div className="mt-3 px-2 space-y-1">
              {isAuthenticated ? (
                <>
                  <a
                    href="/userprofile"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-[#F4A940]"
                  >
                    Profile
                  </a>
                  <a
                    href="/backend"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-[#F4A940]"
                  >
                    Settings
                  </a>
                  <a
                    href={`${API_BASE_URL}/accounts/logout/`}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-[#F4A940]"
                  >
                    Sign out
                  </a>
                </>
              ) : (
                <a
                  href="/login"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-[#F4A940]"
                >
                  Login
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
