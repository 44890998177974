import React from 'react';
import { createPortal } from 'react-dom';

const DialogContext = React.createContext();

export function Dialog({ children, open, onOpenChange }) {
  return (
    <DialogContext.Provider value={{ open, onOpenChange }}>
      {children}
    </DialogContext.Provider>
  );
}

export function DialogContent({ children, className = '', ...props }) {
  const { open, onOpenChange } = React.useContext(DialogContext);

  if (!open) return null;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black/50"
        onClick={() => onOpenChange(false)}
      />
      <div
        className={`relative bg-white rounded-lg shadow-lg p-6 w-full max-w-md m-4 ${className}`}
        {...props}
      >
        {children}
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={() => onOpenChange(false)}
        >
          ×
        </button>
      </div>
    </div>,
    document.body
  );
}

export function DialogHeader({ className = '', ...props }) {
  return (
    <div
      className={`space-y-1.5 text-center sm:text-left ${className}`}
      {...props}
    />
  );
}

export function DialogTitle({ className = '', ...props }) {
  return (
    <h3
      className={`text-lg font-semibold leading-none tracking-tight ${className}`}
      {...props}
    />
  );
}

export function DialogDescription({ className = '', ...props }) {
  return <p className={`text-sm text-gray-500 ${className}`} {...props} />;
}
