export function Badge({ children, className, ...props }) {
  return (
    <span
      className={`inline-flex items-center px-2 py-1 text-xs font-medium bg-gray-100 text-gray-800 rounded ${className}`}
      {...props}
    >
      {children}
    </span>
  );
}
