import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
  Clock,
  Camera,
  Copy,
  CopyCheck,
  LinkIcon,
  History,
  Home,
  Star,
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from '../components/ui/card';
import { Button } from '../components/ui/button';
import { cn } from '../lib/utils';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../components/ui/dialog';
import RefreshButton from './RefreshButton';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function SnapshotInfo({
  timeCreated,
  timeUpdated,
  originalUrl,
  quickUrl,
  uuid,
}) {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(quickUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  // 快照連結跳轉頁面時無法加載出內容，暫時的辦法：
  const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';

  const quickReviseUrl = quickUrl.startsWith('http')
    ? quickUrl
    : `${protocol}://${quickUrl}`;

  const handleBackHome = () => {
    navigate('/'); // 導航到首頁路由
  };

  return (
    <Card className="!p-1 m-4 font-bold">
      <CardHeader className="flex flex-row">
        <CardTitle className="flex items-center text-lg font-bold">
          <Camera className="h-5 w-5 mr-2" />
          快照資訊
        </CardTitle>
      </CardHeader>
      <CardContent className="px-6 pt-0 pb-3">
        <div className="items-center space-y-2">
          {/* 原始連結 block */}
          <div className="block-url flex items-center gap-2 text-sm">
            <LinkIcon className="h-4 w-4 text-muted-foreground flex-shrink-0" />
            <a
              href={originalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-yellow-600 hover:text-yellow-800 hover:no-underline flex items-center flex-grow"
            >
              <span className="font-medium">原始連結</span>
            </a>
          </div>
          {/* 快照連結 block */}
          <div className="block-url flex items-center gap-2 flex-wrap">
            <LinkIcon className="h-4 w-4 text-muted-foreground flex-shrink-0" />
            <a
              href={quickReviseUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-yellow-600 hover:text-yellow-800 hover:no-underline flex items-center flex-grow"
            >
              <span className="font-medium">{quickReviseUrl}</span>
            </a>
            <Button
              variant="outline"
              size="sm"
              className={cn(
                'shrink-0 !p-0.5',
                copied &&
                  'bg-green-50 text-green-600 hover:bg-green-50 hover:text-green-600'
              )}
              onClick={handleCopy}
            >
              {copied ? (
                <CopyCheck className="h-4 w-4 mr-1" />
              ) : (
                <Copy className="h-4 w-4 mr-1" />
              )}
            </Button>
          </div>
          {/* 時間區塊 */}
          <div className="block-time text-sm flex items-center gap-2">
            <Clock className="h-4 w-4 text-muted-foreground" />
            <span className="font-medium">建立時間</span>
            <span className="text-muted-foreground">{timeCreated}</span>
          </div>
          <div className="block-time text-sm flex items-center gap-2">
            <Clock className="h-4 w-4 text-muted-foreground" />
            <span className="font-medium">更新時間</span>
            <span className="text-muted-foreground">{timeUpdated}</span>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button
          variant="outline"
          size="sm"
          onClick={handleBackHome}
          className="mr-2"
        >
          <Home className="h-4 w-4 mr-2" />
          回到首頁
        </Button>
        {/* <Button variant="outline" size="sm" className="mr-2">
          <History className="h-4 w-4 mr-2" />
          查看歷史紀錄
        </Button> */}

        <FavoriteButton jobUuid={uuid} />
      </CardFooter>
    </Card>
  );
}

const FavoriteButton = ({ jobUuid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [comments, setComments] = useState('');
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getCsrfToken = () => {
    const cookies = new Cookies();
    const csrfToken = cookies.get('csrftoken'); // 獲取名為 'csrftoken' 的 Cookie

    if (!csrfToken) {
      console.error('CSRF token not found in cookies.');
    }
    return csrfToken;
  };

  useEffect(() => {
    // 在組件掛載時檢查是否已收藏
    const checkFavorite = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/favorites/`, {
          method: 'GET',
          credentials: 'include', // 攜帶 Cookies
        });
        if (response.ok) {
          const data = await response.json();
          const isFav = data.some((fav) => fav.job.uuid === jobUuid);
          setIsFavorited(isFav);
        }
      } catch (error) {
        console.error(error.message);
        setErrorMessage(`${error.message}`);
        setIsErrorDialogOpen(true);
      }
    };
    checkFavorite();
  }, [jobUuid]);

  const handleFavorite = async () => {
    setIsLoading(true);
    try {
      const csrftoken = getCsrfToken(); // 從 Cookie 獲取 CSRF Token
      let url = '';
      let method = 'POST';

      if (!isFavorited) {
        // 添加收藏
        url = '/api/favorites/add_favorite/';
      } else {
        // 取消收藏
        url = '/api/favorites/remove_favorite/';
      }

      const response = await fetch(`${API_BASE_URL}${url}`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken, // 將 CSRF Token 包含在標頭中
        },
        credentials: 'include', // 確保攜帶 Cookies
        body: JSON.stringify({
          job_uuid: jobUuid,
          comments: comments,
        }),
      });

      const data = await response.json();

      if (response.status === 201 || response.status === 200) {
        // 收藏成功或取消收藏成功
        setIsFavorited(!isFavorited);
      } else {
        console.error('操作失敗:', data.detail || '未知錯誤');
        setErrorMessage(`${data.detail || '未知錯誤'}`);
        setIsErrorDialogOpen(true);
      }
    } catch (error) {
      console.error('操作失敗:', error.message);
      setErrorMessage(`${error.message}`);
      setIsErrorDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonText = () => {
    if (isFavorited) return '已收藏';
    if (isLoading) return '收藏中...';
    return '收藏職缺';
  };

  const closeErrorDialog = () => {
    setIsErrorDialogOpen(false);
    setErrorMessage('');
  };

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        className="mr-2"
        onClick={handleFavorite}
        disabled={isLoading}
      >
        <Star
          className={`h-4 w-4 mr-2 ${isFavorited ? 'text-red-500' : 'text-gray-500'}`}
        />
        {getButtonText()}
      </Button>

      {/* Error Dialog */}
      <Dialog open={isErrorDialogOpen} onOpenChange={setIsErrorDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>操作失敗</DialogTitle>
          </DialogHeader>
          <DialogDescription>{errorMessage}</DialogDescription>
          <div className="mt-4">
            <Button onClick={closeErrorDialog} variant="secondary">
              關閉
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
