import React from 'react';
import { Book } from 'lucide-react';

export function SubmitInstructions() {
  return (
    <div className="mb-4 p-4 rounded-md bg-green-50 border border-green-200 font-sans">
      <div className="flex items-center mb-2">
        <Book className="h-5 w-5 text-green-500 mr-2" />
        <h3 className="text-lg font-semibold text-green-700">使用說明</h3>
      </div>
      <ol className="text-sm list-decimal list-inside space-y-1 text-green-600">
        <div>
          這是一個職缺備份網站，目前其他功能尚在開發中，若在使用中遇到任何問題，可以填寫問卷回報：
          <a href="https://forms.gle/geyBVVsEjpaXwWo68" target="_blank">
            https://forms.gle/geyBVVsEjpaXwWo68
          </a>
        </div>

        <li>目前僅支援104、1111、CakeResume的職缺連結</li>
        <li>請輸入完整的職缺URL，不可帶有文字</li>
        <li>確保連結是有效且可訪問的</li>
        <li>點擊「Snapshot」按鈕後，請稍等片刻，系統正在處理此次備份</li>
        <li>跳轉至備份網頁後，保存備份連結，即可不再擔心職缺被關閉。</li>
      </ol>
    </div>
  );
}
