import React, { useEffect, useState } from 'react';
import { Button } from '../../components/ui/button';
import MainLayout from '../../layouts/MainLayout';
import Submit from '../Submit/index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Home() {
  useEffect(() => {
    fetch(`${API_BASE_URL}/csrf/`, {
      method: 'GET',
      credentials: 'include', // 確保 Cookie 被存儲
    })
      .then((response) => (response = response.json()))
      .catch((error) => console.error('Error initializing CSRF token:', error));
  }, []);

  return <Submit />;
}
