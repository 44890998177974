'use client';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';
import { Camera, Briefcase, Search } from 'lucide-react';
import { motion } from 'framer-motion';

export default function NotFound() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-[#F4A940]/10">
      <Card className="max-w-2xl mx-auto p-8 text-center space-y-8">
        <div className="space-y-4">
          <motion.div
            className="flex justify-center space-x-4"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              animate={{
                rotate: [0, -10, 10, -10, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: 'reverse',
              }}
            >
              <Camera size={48} className="text-[#F4A940]" />
            </motion.div>
            <motion.div
              animate={{
                y: [0, -5, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: 'reverse',
              }}
            >
              <Briefcase size={48} className="text-[#F4A940]" />
            </motion.div>
            <motion.div
              animate={{
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: 'reverse',
              }}
            >
              <Search size={48} className="text-[#F4A940]" />
            </motion.div>
          </motion.div>
          <motion.h1
            className="text-6xl font-bold text-[#F4A940]"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            404
          </motion.h1>
          <motion.p
            className="text-2xl text-gray-600"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            噢！找不到您要的職缺，該職缺可能已經關閉了。
          </motion.p>
        </div>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <p className="text-gray-500 mb-6">已購買，小孩愛吃。</p>
          <Button
            aschild="true"
            className="bg-[#F4A940] hover:bg-[#F4A940]/90 text-white"
          >
            <a href="/">首頁</a>
          </Button>
        </motion.div>
      </Card>
    </div>
  );
}
