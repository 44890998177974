import React, { useState } from 'react';
import DjangoCSRFToken from 'django-react-csrftoken';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Camera, Loader2 } from 'lucide-react';
import { SubmitInstructions } from '../../components/SubmitInstructions';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Submit() {
  const [jobUrl, setJobUrl] = useState('');
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Added state for loading
  const navigate = useNavigate();

  const getCsrfToken = () => {
    const cookies = new Cookies();
    const csrfToken = cookies.get('csrftoken'); // 獲取名為 'csrftoken' 的 Cookie

    if (!csrfToken) {
      console.error('CSRF token not found in cookies.');
    }
    return csrfToken;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const csrftoken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/lunar-lake/submit/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': csrftoken, // 確保 CSRF token
        },
        body: new URLSearchParams({ 'job-url': jobUrl }),
      });

      if (response.ok) {
        const data = await response.json();
        const result = data['data']['result'];
        navigate(`/snapshot/${result['source']}/${result['uuid']}/`);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || '提交失敗，請稍後再試。');
        setIsErrorDialogOpen(true);
      }
    } catch (error) {
      console.error('提交失敗：', error);
      setErrorMessage('該職缺已關閉，且資料庫未儲存該筆職缺資料。');
      setIsErrorDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-md p-6 bg-white rounded-lg shadow-md"
      >
        <DjangoCSRFToken />
        <h1 className="text-xl font-bold mb-4">職缺的時光膠囊</h1>
        <SubmitInstructions />
        <div className="flex items-center space-x-2">
          <Input
            type="url"
            value={jobUrl}
            onChange={(e) => setJobUrl(e.target.value)}
            required
            disabled={isLoading}
          />
          <Button
            type="submit"
            className="font-medium flex items-center bg-[#F4A940] hover:bg-[#F4A940]/90 text-white"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader2 className="h-5 w-5 animate-spin mr-1" />
            ) : (
              <Camera className="h-5 w-6 mr-1" />
            )}
            {isLoading ? 'Search...' : 'Snapshot'}
          </Button>
        </div>
      </form>

      <Dialog open={isErrorDialogOpen} onOpenChange={setIsErrorDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {errorMessage.includes('成功') ? '成功' : '錯誤'}
            </DialogTitle>
            <DialogDescription>{errorMessage}</DialogDescription>
          </DialogHeader>
          <div className="mt-4 flex justify-end">
            <Button onClick={() => setIsErrorDialogOpen(false)}>關閉</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
