import React, { useState, useRef, useEffect } from 'react';
import styles from '../../styles/dropdown-menu.module.css';

export const DropdownMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={ref} className={styles.dropdownMenu}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { isOpen, setIsOpen })
      )}
    </div>
  );
};

export const DropdownMenuTrigger = ({ children, setIsOpen, ...props }) => {
  return (
    <button
      className={styles.dropdownMenuTrigger}
      onClick={() => setIsOpen((prev) => !prev)} // 點擊切換 isOpen
      {...props}
    >
      {children}
    </button>
  );
};

export const DropdownMenuContent = ({ children, isOpen }) => {
  return (
    <div
      className={`${styles.dropdownMenuContent} ${
        isOpen ? styles.open : styles.closed
      }`}
    >
      {children}
    </div>
  );
};

// export const DropdownMenuItem = ({ children, ...props }) => {
//   return (
//     <button className={styles.dropdownMenuItem} {...props}>
//       {children}
//     </button>
//   );
// };
export const DropdownMenuItem = ({
  as: Component = 'button',
  children,
  ...props
}) => {
  return (
    <Component className={styles.dropdownMenuItem} {...props}>
      {children}
    </Component>
  );
};
