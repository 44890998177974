import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from '../../components/ui/card';
import { cn } from '../../lib/utils';
import { Badge } from '../../components/ui/badge';
import {
  Clock,
  Star,
  Mail,
  MapPin,
  ChevronDown,
  Users,
  Camera,
  Copy,
  CopyCheck,
  LinkIcon,
  History,
  ExternalLink,
  Home,
} from 'lucide-react';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { useNavigate } from 'react-router-dom';
import SnapshotInfo from '../../components/snapshotInfo';
import formatDateTime from '../../components/formateDateTime';

export default function Details104() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const HOST = window.location.host;
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    fetch(`${API_BASE_URL}/lunar-lake/${uuid}/`, {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${yourAuthToken}` // 如果需要 Token
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (data?.status === 200 && data?.data?.result) {
          return data.data.result;
        } else {
          throw new Error('Invalid response structure');
        }
      })
      .then((result) => setJobData(result))
      .catch((error) => {
        console.error('Error fetching job data:', error);
        navigate('/404');
      });
  }, [uuid, navigate]);

  if (!jobData) {
    return <p>Loading...</p>;
  }

  const companyOtherJob = `${API_BASE_URL}/lunar-lake/other/company/${jobData.id_company.id}`;
  console.log('testtttttt', companyOtherJob);
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <SnapshotInfo
        timeCreated={formatDateTime(jobData.time_created)}
        timeUpdated={formatDateTime(jobData.time_updated)}
        originalUrl={jobData.original_url}
        quickUrl={`${HOST}/snapshot/${jobData.source}/${jobData.uuid}`}
        uuid={jobData.uuid}
      />
      <div className="max-w-6xl mx-auto p-4 grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Main Content */}

        <div className="md:col-span-2 space-y-6">
          {/* Header Section */}
          <Card className="p-6">
            <div className="flex justify-between items-start">
              <div>
                <div className="flex items-center gap-2 mb-2">
                  <h1 className="text-xl font-medium">{jobData.title}</h1>
                  <Badge variant="outline">
                    {jobData.time_updated_original} 更新
                  </Badge>
                </div>
                <div className="text-sm mb-2">
                  <a href="#" className="text-blue-600 hover:underline mr-4">
                    {jobData.company_name}
                  </a>
                  <a href="#" className="text-blue-600 hover:underline">
                    本公司其他工作
                  </a>
                </div>
                <div className="flex items-center text-sm text-gray-500">
                  <Users className="h-4 w-4 mr-1" />
                  應徵人數 {jobData.applicant_nums}
                </div>
              </div>
              {/* <div className="flex gap-2">
                <Button variant="outline" size="sm">
                  <Star className="h-4 w-4 mr-1" />
                  儲存
                </Button>
                <Button variant="onezerofour" size="sm">
                  <Mail className="h-4 w-4 mr-1" />
                  應徵
                </Button>
              </div> */}
            </div>
          </Card>
          <Card className="p-6">
            {/* Job Content */}
            <div className="space-y-6">
              <section>
                <div>
                  <h1 className="font-bold mb-3">工作內容</h1>
                  <pre className="whitespace-pre-wrap space-y-4 text-gray-700">
                    {jobData.description}
                  </pre>
                </div>
              </section>

              {/* Job Details */}
              <div className="block gap-4 text-sm">
                <div>
                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      職務類別
                    </span>
                    <div className="flex flex-wrap gap-2 mt-0">
                      {jobData['classification'].length > 0 ? (
                        jobData['classification'].map((skill) => (
                          <Badge
                            key={skill}
                            variant="secondary"
                            className="text-sm"
                          >
                            {skill}
                          </Badge>
                        ))
                      ) : (
                        <span>不拘</span>
                      )}
                    </div>
                  </div>
                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      工作待遇
                    </span>
                    <div className="mt-0 text-orange-500 font-semibold">
                      {jobData.salary}
                    </div>
                  </div>
                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      工作性質
                    </span>
                    <div className="mt-0">
                      {jobData?.type?.type} {/* 渲染 "全職" */}
                      {Array.isArray(jobData?.type?.other) &&
                        jobData.type.other.length > 0 &&
                        `, ${jobData.type.other.join(', ')}`}{' '}
                      {/* 渲染其他類型 */}
                    </div>
                  </div>

                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      上班地點
                    </span>
                    <div className="flex items-center mt-0">
                      <span>{jobData.location}</span>
                      <MapPin className="h-4 w-4 ml-1 text-gray-500" />
                    </div>
                  </div>

                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      管理責任
                    </span>
                    <p className="mt-0">{jobData.required_management}</p>
                  </div>

                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      出差外派
                    </span>
                    <div className="mt-0">{jobData.required_business}</div>
                  </div>
                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      上班時段
                    </span>
                    <div className="mt-0">{jobData.time_working}</div>
                  </div>

                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      休假制度
                    </span>
                    <div className="mt-0">{jobData.time_vacation}</div>
                  </div>
                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      可上班日
                    </span>
                    <div className="mt-0">{jobData.date_arrival}</div>
                  </div>
                  <div className="mb-2 flex items-center">
                    <span className="text-black-600 font-bold mr-5">
                      需求人數
                    </span>
                    <div className="mt-0">{jobData.applicant_need}</div>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          {/* 條件要求 */}
          <Card className="p-6">
            <h1 className="font-bold mb-4">條件要求</h1>
            <div className="block gap-4 text-sm">
              <div className="mb-2 flex items-center">
                <span className="text-black-600 font-bold mr-5">工作經歷</span>
                <div className="mt-0">{jobData.required_experience}</div>
              </div>
              <div className="mb-2 flex items-center">
                <span className="text-black-600 font-bold mr-5">學歷要求</span>
                <div className="mt-0">{jobData.required_education}</div>
              </div>
              <div className="mb-2 flex items-center">
                <span className="text-black-600 font-bold mr-5">科系要求</span>
                <RequiredList jobData={jobData.required_major} />
              </div>
              <div className="mb-2 flex items-center">
                <span className="text-black-600 font-bold mr-5">語文條件</span>
                {/* <RequiredLanguage jobData={jobData.required_language} /> */}
                <RequiredLanguage jobData={jobData?.required_language || []} />
              </div>

              <div className="mb-2 flex items-center">
                <span className="text-black-600 font-bold mr-5">擅長工具</span>
                <RequiredList jobData={jobData.required_tool} />
              </div>

              <div className="mb-2 flex items-center">
                <span className="text-black-600 font-bold mr-5">工作技能</span>
                <RequiredList jobData={jobData?.required_skill} />
              </div>

              <RequiredOther
                title="具備證照"
                data={jobData.required_other?.certificate}
              />

              <RequiredOther
                title="具備駕照"
                data={jobData.required_other?.driverLicense}
              />

              <div className="mb-2 items-center">
                <span className="text-black-600 font-bold mr-5">其他條件</span>
                <pre className="whitespace-pre-wrap mt-2 ml-2">
                  {jobData.required_other.other}
                </pre>
              </div>
            </div>
          </Card>
          {/* Additional Sections */}
          <Card className="p-6">
            <h1 className="font-bold mb-4">福利制度</h1>
            <pre className="whitespace-pre-wrap text-sm text-gray-700 space-y-2">
              {jobData.benefits}
            </pre>
          </Card>

          <Card className="p-6">
            <h1 className="font-bold mb-4">聯絡方式</h1>
            <div className="text-sm text-gray-700">{/* {job.apply_way} */}</div>
          </Card>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          <OtherJobListings
            apiUrl={companyOtherJob}
            companyName={jobData.company_name}
          />
        </div>
      </div>
    </div>
  );
}

export function RequiredList({ jobData = '不拘' }) {
  return (
    <div className="flex flex-wrap gap-2 mt-0">
      {typeof jobData === 'string' && jobData.trim() === '不拘' ? (
        <span>不拘</span>
      ) : Array.isArray(jobData) ? (
        jobData.length > 0 ? (
          jobData.map((data, index) => (
            <Badge key={index} variant="secondary" className="text-sm">
              {data}
            </Badge>
          ))
        ) : (
          <span>不拘</span>
        )
      ) : (
        <span>資料格式錯誤</span>
      )}
    </div>
  );
}

export function OtherJobListings({ apiUrl, companyName }) {
  const [jobListings, setJobListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobListings = async () => {
      try {
        setIsLoading(true);
        let response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch job listings');
        }

        const data = await response.json();
        setJobListings(data.data.result);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobListings();
  }, [apiUrl]);

  if (isLoading) {
    return (
      <Card className="p-6">
        <p>載入中...</p>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="p-6">
        <p className="text-red-600">載入失敗: {error}</p>
      </Card>
    );
  }

  return (
    <Card className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-medium">{companyName} 其他職缺</h2>
        <Button variant="ghost" size="sm">
          <ChevronDown className="h-4 w-4" />
        </Button>
      </div>
      <div className="space-y-4">
        {jobListings.length > 0 ? (
          jobListings.map((job, i) => (
            <div key={i} className="pb-4 border-b last:border-0">
              <h3 className="font-medium mb-1">
                <a
                  href={`/snapshot/104/${job.uuid}`}
                  className="text-blue-600 hover:underline"
                >
                  {job.title}
                </a>
              </h3>
              <p className="text-sm text-gray-600">{job.company}</p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">目前無其他職缺</p>
        )}
      </div>
      <Button variant="ghost" size="sm" className="w-full mt-4">
        顯示更多
      </Button>
    </Card>
  );
}

export function RequiredLanguage({ jobData }) {
  const safeJobData = Array.isArray(jobData) ? jobData : [];

  return (
    <div className="flex flex-wrap gap-2 mt-0">
      {safeJobData.length > 0 ? (
        safeJobData.map((data, index) => (
          <Badge key={index} variant="secondary" className="text-sm">
            {`${data.language} (${data.ability})`}
          </Badge>
        ))
      ) : (
        <span>不拘</span>
      )}
    </div>
  );
}

export function RequiredOther({ title, data }) {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // 如果不是陣列或陣列為空，則不渲染
  }

  return (
    <div className="mb-2 flex items-center">
      <span className="text-black-600 font-bold mr-5">{title}</span>
      <div className="flex flex-wrap gap-2 mt-0">
        {data.map((item, index) => (
          <Badge key={index} variant="secondary" className="text-sm">
            {item}
          </Badge>
        ))}
      </div>
    </div>
  );
}
