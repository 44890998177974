'use client';

import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Separator } from '../components/ui/separator';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import {
  MapPin,
  Briefcase,
  Network,
  User,
  History,
  House,
  Share2,
  MessageSquare,
  Bookmark,
  Clock,
  Building2,
  DollarSign,
  Mail,
} from 'lucide-react';
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';

export default function JobSidebar({ data }) {
  return (
    <div className="space-y-4">
      {/* 儲存按鈕 */}
      <div className="flex gap-2">
        <Button variant="outline" className="flex-1">
          <Bookmark className="w-4 h-4 mr-2" />
          儲存
        </Button>
        <Button className="flex-1 bg-green-600 hover:bg-green-700">
          立即應徵
        </Button>
      </div>

      {/* 工作資訊卡片 */}
      <Card className="p-4">
        <div className="space-y-4">
          <div className="flex items-start gap-3">
            <div>
              <div className="font-medium">
                {data['type'].type}・{data['type']?.level || 'N/A'}
              </div>
            </div>
          </div>
          <div className="flex items-start gap-3">
            <MapPin
              className="w-5 h-5 text-muted-foreground mt-0.5"
              title="地址"
            />
            <div className="font-medium">{data.location}</div>
          </div>

          <div className="flex items-start gap-3">
            <User
              className="w-5 h-5 text-muted-foreground mt-0.5"
              title="招募人數"
            />
            <div className="font-medium">{data.applicant_need}</div>
          </div>
          {/* 經驗要求 */}
          <div className="flex items-start gap-3">
            <Briefcase className="w-5 h-5 text-muted-foreground mt-0.5" />
            <div className="font-medium">
              具備 {data.required_experience} 工作經驗
            </div>
          </div>
          {/* 薪資範圍 */}
          <div className="flex items-start gap-3">
            <DollarSign className="w-5 h-5 text-muted-foreground mt-0.5" />
            <div>
              <div className="font-medium">{data.salary}</div>
            </div>
          </div>
          <div className="flex items-start gap-3">
            <House className="w-5 h-5 text-muted-foreground mt-0.5" />
            <div className="font-medium">{data.required_other.remote}</div>
          </div>
          <div className="flex items-start gap-3">
            <Network className="w-5 h-5 text-muted-foreground mt-0.5" />
            <div className="font-medium">{data.required_management}</div>
          </div>
          <SkillTags skills={data.required_other.tag} />
        </div>
      </Card>
    </div>
  );
}

export function SkillTags({ skills }) {
  return (
    <div className="flex flex-wrap gap-2">
      {skills.map((skill) => (
        <Button
          key={skill}
          variant="outline"
          className="rounded-md bg-white hover:bg-gray-50 text-gray-600 border-gray-200 px-2.5 py-0.5 h-auto text-sm font-normal hover:text-gray-900 hover:border-gray-300"
        >
          {skill}
        </Button>
      ))}
    </div>
  );
}
