import * as React from 'react';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/button';
import { ChevronRight } from 'lucide-react';

const SidebarContext = React.createContext({});

export function Sidebar({ className, children, ...props }) {
  const { isCollapsed } = React.useContext(SidebarContext);

  return (
    <div className={cn('flex h-screen', className)} {...props}>
      <div
        className={cn(
          'bg-background flex flex-col border-r transition-all duration-300 ease-in-out',
          isCollapsed ? 'w-16' : 'w-64'
        )}
      >
        {children}
      </div>
    </div>
  );
}

export function SidebarHeader({ className, children, ...props }) {
  return (
    <div className={cn('px-3 py-2', className)} {...props}>
      {children}
    </div>
  );
}

export function SidebarContent({ className, children, ...props }) {
  const { isCollapsed } = React.useContext(SidebarContext);
  return (
    <div className={cn('flex-1 overflow-auto', className)} {...props}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { isCollapsed })
      )}
    </div>
  );
}

export function SidebarFooter({ className, children, ...props }) {
  return (
    <div className={cn('px-3 py-2', className)} {...props}>
      {children}
    </div>
  );
}

export function SidebarMenu({ className, children, ...props }) {
  return (
    <nav className={cn('space-y-1', className)} {...props}>
      {children}
    </nav>
  );
}

export function SidebarMenuItem({
  className,
  children,
  isCollapsed,
  ...props
}) {
  return (
    <div className={cn('', className)} {...props}>
      {isCollapsed
        ? React.Children.map(children, (child) =>
            React.cloneElement(child, {
              className: cn(child.props.className, 'w-12 h-12 p-0'),
              children: child.props.children[0],
            })
          )
        : children}
    </div>
  );
}

export function SidebarMenuButton({ className, children, ...props }) {
  const { isCollapsed } = React.useContext(SidebarContext);

  return (
    <Button
      variant="ghost"
      className={cn(
        'w-full justify-start',
        isCollapsed ? 'px-2' : 'px-3',
        className
      )}
      {...props}
    >
      {isCollapsed ? children[0] : children}
    </Button>
  );
}

export function SidebarTrigger({ className, ...props }) {
  const { isCollapsed, setIsCollapsed } = React.useContext(SidebarContext);

  return (
    <Button
      variant="ghost"
      size="icon"
      className={cn('absolute top-2 -right-4 z-10', className)}
      onClick={() => setIsCollapsed(!isCollapsed)}
      {...props}
    >
      <ChevronRight
        className={cn(
          'h-4 w-4 transition-transform',
          isCollapsed ? 'rotate-0' : 'rotate-180'
        )}
      />
    </Button>
  );
}

export function SidebarProvider({ children }) {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  return (
    <SidebarContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      {children}
    </SidebarContext.Provider>
  );
}
