import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Flame,
  CalendarClock,
  Share2,
  Printer,
  BookmarkPlus,
  MapPin,
  Clock,
  Building2,
  Briefcase,
  GraduationCap,
  DollarSign,
  Users,
} from 'lucide-react';
import { Badge } from '../../components/ui/badge';
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../../components/ui/tabs';
// import Image from "next/image"
import SnapshotInfo from '../../components/snapshotInfo';
import formatDateTime from '../../components/formateDateTime';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Details1111() {
  const { uuid } = useParams();
  const [jobData, setJobData] = useState(null);
  const HOST = window.location.host;
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${API_BASE_URL}/lunar-lake/${uuid}/`)
      .then((response) => response.json())
      .then((data) => {
        setJobData(data.data.result);
      })
      .catch((error) => {
        console.error('Error fetching job data:', error);
        navigate('/404');
      });
  }, []);

  if (!jobData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="contents">
      <SnapshotInfo
        timeCreated={formatDateTime(jobData.time_created)}
        timeUpdated={formatDateTime(jobData.time_updated)}
        originalUrl={jobData.original_url}
        quickUrl={`${HOST}/snapshot/${jobData.source}/${jobData.uuid}`}
        uuid={jobData.uuid}
      />
      <div className="max-w-4xl mx-auto p-4 m-4">
        {/* Header Section */}
        <div className="mb-4 px-2">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h1 className="text-2xl font-bold mb-2">{jobData.title}</h1>
              <span className="text-sm text-gray-500">
                {jobData.time_updated_original}
              </span>
            </div>
          </div>

          {/* Basic Info */}
          <div className="flex flex-wrap gap-6 mb-4">
            <JobItemInfo
              Icon={GraduationCap}
              colValue={jobData.required_education}
            />
            <JobItemInfo Icon={DollarSign} colValue={jobData.salary} />
            <JobItemInfo Icon={MapPin} colValue={jobData.location} />
          </div>

          {/* Application Stats */}
          <div className="flex gap-6 text-sm text-gray-600">
            {/* 應徵人數是動態 render 所以沒資料 */}
            <JobItemDetail
              Icon={Flame}
              colName="應徵人數"
              colValue={jobData.applicant_nums}
            />
            <JobItemDetail
              Icon={Users}
              colName="需求人數"
              colValue={jobData.applicant_need}
            />
            <JobItemDetail
              Icon={CalendarClock}
              colName="到職日"
              colValue={jobData.date_arrival}
            />
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {/* Job Content Tabs */}
          <Tabs defaultValue="content" className="w-full">
            <TabsList className="w-full justify-start h-auto p-0 bg-transparent border-b">
              <TabsTrigger
                value="content"
                className="data-[state=active]:border-b-2 data-[state=active]:border-blue-500 rounded-none px-6 py-2"
              >
                工作內容
              </TabsTrigger>
              <TabsTrigger
                value="info"
                className="data-[state=active]:border-b-2 data-[state=active]:border-blue-500 rounded-none px-6 py-2"
              >
                工作資訊
              </TabsTrigger>
              <TabsTrigger
                value="requirements"
                className="data-[state=active]:border-b-2 data-[state=active]:border-blue-500 rounded-none px-6 py-2"
              >
                要求條件
              </TabsTrigger>
            </TabsList>

            <TabsContent value="content" className="mt-6">
              <Card>
                <CardContent className="p-6">
                  <div className="mb-8">
                    <div className="ui_card_company">
                      <a
                        href="#"
                        target="_blank"
                        className="ui_card_company_link"
                      >
                        <span className="font-bold text-lg">
                          {jobData.company_name}
                        </span>
                        <p className="body_3">公司類型</p>
                      </a>
                      <a
                        href="#"
                        className="btn_secondary_5 btn_number"
                        target="_blank"
                      >
                        更多職缺
                      </a>
                    </div>
                  </div>

                  <div className="prose max-w-none">
                    <h3 className="text-xl font-semibold mb-4">工作內容</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobData.description.replace(/\n/g, '<br>'),
                      }}
                    />
                  </div>

                  <div className="flex gap-2 mt-6">
                    <Button variant="outline" size="md">
                      <Building2 className="w-4 h-4 mr-2"></Building2>
                      {jobData.company_name}
                    </Button>
                    {/* <Button variant="outline" size="sm">
                                <Share2 className="w-4 h-4 mr-2" />
                                分享
                            </Button>
                            <Button variant="outline" size="sm">
                                <Printer className="w-4 h-4 mr-2" />
                                列印
                            </Button>
                            <Button variant="outline" size="sm">
                                <BookmarkPlus className="w-4 h-4 mr-2" />
                                收藏
                            </Button>
                            <div className="ml-auto">
                                <Button className="bg-teal-500 hover:bg-teal-600">
                                應徵
                                </Button> */}
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="info" className="mt-6">
              <Card>
                <CardContent className="p-6 space-y-6">
                  <h3 className="text-xl font-semibold mb-4">工作資訊</h3>
                  <JobInfoRow
                    colName="工作時間"
                    colValue={jobData.time_working}
                  />
                  <JobInfoRow
                    colName="休假制度"
                    colValue={jobData.time_vacation}
                  />
                  <JobInfoRow colName="工作待遇" colValue={jobData.salary} />
                  <JobInfoRow colName="工作性質" colValue={jobData.type} />
                  <div className="flex items-start">
                    <h4 className="font-semibold w-24 flex-shrink-0">
                      職務類別
                    </h4>
                    <div>
                      <div className="flex flex-wrap gap-2">
                        {jobData.classification.map((category, index) => (
                          <Badge key={index} variant="secondary">
                            {category}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </div>

                  <JobInfoRow colName="工作地點" colValue={jobData.location} />
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="requirements" className="mt-6">
              <Card>
                <CardContent className="p-6 space-y-6">
                  <h3 className="text-xl font-semibold mb-4">要求條件</h3>

                  <JobInfoRow
                    colName="工作經驗"
                    colValue={jobData.required_experience}
                  />
                  <JobInfoRow
                    colName="學歷限制"
                    colValue={jobData.required_education}
                  />
                  <JobInfoRow
                    colName="科系限制"
                    colValue={jobData.required_major}
                  />
                  <JobInfoRow
                    colName="語言能力"
                    colValue={jobData.required_language}
                  />

                  <OtherRequirements required_other={jobData.required_other} />
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>

          {/* Company Benefits */}
          <Card>
            <CardHeader>
              <CardTitle>公司福利</CardTitle>
            </CardHeader>
            <CardContent>
              <pre>
                {Object.entries(jobData.benefits)
                  .map(([key, value]) => `${key}:\n${value}`)
                  .join('\n\n')}
              </pre>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export function JobItemDetail({ Icon, colName, colValue }) {
  return (
    <div className="flex items-center gap-1">
      <Icon className="w-4 h-4" />
      <span>
        {colName}：{colValue}
      </span>
    </div>
  );
}

export function JobItemInfo({ Icon, colValue }) {
  return (
    <div className="flex items-center gap-2">
      <Icon className="w-4 h-4 text-blue-500" />
      <span>{colValue}</span>
    </div>
  );
}

export function JobInfoRow({ colName, colValue }) {
  return (
    <div className="flex items-start">
      <h4 className="font-semibold w-24 flex-shrink-0">{colName}</h4>
      <div>
        <div dangerouslySetInnerHTML={{ __html: colValue }} />
      </div>
    </div>
  );
}

export function OtherRequirements({ required_other }) {
  const parsedRequiredOther = required_other
    ? typeof required_other === 'string'
      ? JSON.parse(required_other)
      : required_other
    : {};

  return (
    <div className="flex items-start">
      <h4 className="font-semibold w-24 flex-shrink-0">其他說明</h4>
      <div>
        <div className="flex flex-col gap-4">
          {Object.entries(parsedRequiredOther)
            .filter(([_, value]) => value !== '' && value !== null) // 篩選掉空字串和 null
            .map(([key, value]) => (
              <JobInfoRow key={key} colName={key} colValue={value} />
            ))}
        </div>
      </div>
    </div>
  );
}
