import React, { useState } from 'react';
import { Button } from '../../components/ui/button';
import {
  Search,
  Menu,
  Bell,
  User,
  Home,
  Star,
  BookOpen,
  Settings,
  HelpCircle,
  LogOut,
} from 'lucide-react';
import styles from '../../styles/backend-dashboard.module.css';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
} from '../../components/ui/sidebar';
import DashboardContent from './DashboardContent';
import FavoritesContent from './FavoritesContent';
// import ApplicationsContent from './ApplicationsContent';
// import SettingsContent from './SettingsContent';
// import HelpContent from './HelpContent';

export default function Dashboard() {
  const [activeContent, setActiveContent] = useState('dashboard');

  const renderContent = () => {
    switch (activeContent) {
      // case 'dashboard':
      //   return <DashboardContent />;
      case 'favorites':
        return <FavoritesContent />;
      // case 'applications':
      //   return <ApplicationsContent />;
      // case 'settings':
      //   return <SettingsContent />;
      // case 'help':
      //   return <HelpContent />;
      default:
        return <FavoritesContent />;
    }
  };

  return (
    <SidebarProvider>
      <div className="flex h-screen">
        <Sidebar className="relative">
          <SidebarHeader></SidebarHeader>
          <SidebarContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() => setActiveContent('dashboard')}
                  isActive={activeContent === 'dashboard'}
                  className="flex items-center space-x-2"
                >
                  <Home size={20} />
                  <span>儀表板</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() => setActiveContent('favorites')}
                  isActive={activeContent === 'favorites'}
                  className="flex items-center space-x-2"
                >
                  <Star size={20} />
                  <span>收藏</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() => setActiveContent('applications')}
                  isActive={activeContent === 'applications'}
                  className="flex items-center space-x-2"
                >
                  <BookOpen size={20} />
                  <span>應用程式</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() => setActiveContent('settings')}
                  isActive={activeContent === 'settings'}
                  className="flex items-center space-x-2"
                >
                  <Settings size={20} />
                  <span>設定</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() => setActiveContent('help')}
                  isActive={activeContent === 'help'}
                  className="flex items-center space-x-2"
                >
                  <HelpCircle size={20} />
                  <span>幫助</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarContent>
          <SidebarFooter>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton>
                  <LogOut size={20} />
                  <span>登出</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarFooter>
        </Sidebar>

        <main className="flex-1 overflow-y-auto relative">
          <SidebarTrigger />
          {/* <header className="flex items-center justify-between p-4 border-b">
            <div className="flex items-center space-x-2">
              <Button variant="ghost" size="icon">
                <Bell size={20} />
              </Button>
              <Button variant="ghost" size="icon">
                <User size={20} />
              </Button>
            </div>
          </header> */}
          <div className="p-6">{renderContent()}</div>
        </main>
      </div>
    </SidebarProvider>
  );
}
