import React from 'react';
import AppRouter from './router';
// import "./styles/index.css";

export default function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}
