import { cva } from 'class-variance-authority';

const buttonVariants = cva(
  'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none',
  {
    variants: {
      variant: {
        primary: 'border-transparent bg-black text-white hover:bg-gray-900',
        secondary:
          'border-transparent bg-gray-600 text-white hover:bg-gray-700',
        onezerofour:
          'border-transparent bg-orange-500 text-white hover:bg-orange-600',
        cake: 'border-transparent bg-green-500 text-white hover:bg-green-600',
        ghost:
          'border-[#F4A940] bg-transparent text-[#F4A940] hover:bg-[#F4A940]/10',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

export function Button({ className, variant, ...props }) {
  return (
    <button
      className={`${buttonVariants({ variant })} ${className}`}
      {...props}
    >
      {props.children}
    </button>
  );
}
